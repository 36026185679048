@font-face {
  font-family: "Proxima Nova Alt";
  src: local("Proxima Nova Alt"),
    url(./assets/fonts/Proxima-Nova-Alt.otf) format("otf");
}
/* @import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css); */

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ml-1 {
  margin-left: 1rem;
}

/* .App-logo {
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
} */

/* .App-header {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
} */

/* @keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
} */

/* <============= scroll bar styles =============> */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #888; */
  background: rgb(189, 189, 189);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* <============= animation styles =============> */

.fade-in {
  /* animation: fade-in 1s; */
  transition: all 1s ease-in-out;
  /* transition: opacity 1s ease; */
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

/* privacy policy */
.privacyPolicy {
  font-family: "Varela Round", sans-serif;
  padding: 2rem 5rem;

  text-align: justify; /* For Edge */
  -moz-text-align: justify; /* For Firefox prior 58.0 */
}

.privacyPolicy p {
  color: "#515184";
}

@media screen and (max-width: 768px) {
  .privacyPolicy {
    padding: 2rem;
  }
}

@media screen and (max-width: 425px) {
  .privacyPolicy {
    padding: 2rem 1rem;
  }
}

#scroll-to-bottom {
  font-weight: bold;
  color: green;
}

/* <============= info styles =============> */
.infoSectionWrapper {
  position: relative;
  display: block;
  overflow: visible;
  width: 98%;
  max-width: 1700px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 192px;
}
.infoSectionWrapper__slide {
  position: -webkit-sticky;
  position: sticky;
  bottom: 96px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  /* height: 65vh; */
  height: auto;
  max-width: 100%;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 22px;
  /* background-color: #121212; */
}
.infoSectionWrapper__slide.slide-1 {
  bottom: 18vh;
  z-index: 3;
  margin-top: -15vh;
  /* margin-top: 0; */
  /* margin-bottom: 0; */
  margin-bottom: 15vh;
  /* margin-bottom: 9vh; */
  /* margin-bottom: 6vh; */
  /* background-color: #98b2c0;
  background-image: none; */
  box-shadow: 0 30px 30px 0 rgb(0 0 0 / 30%);
}
.infoSectionWrapper__slide.slide-2 {
  bottom: 12vh;
  z-index: 2;
  margin-top: -7vh;
  /* margin-top: 7vh; */
  margin-bottom: 7vh;
  /* margin-bottom: 0; */
  /* background-color: #fab9cd;
  background-image: none; */
  box-shadow: 0 30px 30px 0 rgb(0 0 0 / 30%);
}
.infoSectionWrapper__slide.slide-3 {
  bottom: 5vh;
  /* bottom: 4vh; */
  z-index: 1;
  margin-top: 0vh;
  /* margin-top: 7vh; */
  /* margin-bottom: 0; */
  box-shadow: 0 30px 30px 0 rgb(0 0 0 / 30%);
  /* background-color: #cfbce5;
  background-image: none; */
  /* opacity: 0.5; */
}

button {
  cursor: pointer !important;
}

.app__smallTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #6e50ff;
  margin-bottom: 0;
}
.app__mainTitle {
  font-family: "Proxima Nova Alt", "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 64px;
  letter-spacing: -0.5px;
  color: #12141d;
  margin-top: 10px;
}

.faq__app__link {
  color: #6e50ff;
  font-weight: bold;
  opacity: 0.8;
  text-decoration: none;
  border-bottom: 1px solid #6e50ffa6;
}
